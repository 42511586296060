html, body {
    color: #212121 !important;
    background-color: #212121 !important;
}

.btnTheme {
    background: linear-gradient(90deg, #8F5E25 0%, #BFA15C 49.23%, #8F5E25 100%), #A98243;
}

.imageModal {
    height: 100px;
    width: 120px;
    color: #090702 !important;
}

.css-8yrho3-MuiFormControl-root>label {
    top: 6px !important;
    left: 0;
    font-weight: 900;
}

.css-sjlwv9 {
    color: rgb(255 255 255) !important;
}

.css-musyot {
    color: rgb(255 255 255) !important; 
}

/* .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
} */